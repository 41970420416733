import React from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql, Link } from "gatsby"
import css from "@styled-system/css"
import ScrollChanger from "react-scroll-changer"

import {
  Section,
  Container,
  Flex,
  H6,
  H2,
  Text,
  Box,
  H1,
  Button,
} from "components"

import Finish from "./_sections/_finish"
import { Swatches } from "vectors"

import studioLottie from "../../../../static/lottie/studioLottie2.json"

const Theme = ({ image, title, subtitle, url }) => (
  <Flex width={["100%", null, 1 / 3]} p={3}>
    <Flex width="100%" flexDirection="column" p={4} bg="background">
      <Box
        css={css({
          backgroundImage:
            image &&
            "url(" +
              image +
              "?fit=crop&crop=center&max-w=600&max-h=600&auto=compress)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        })}
        pb="100%"
      />
      <H6 children={title} mt={[3, 4]} mb={2} />
      {subtitle && <Text children={subtitle} />}
      {url && (
        <Flex alignItems="center" mt={3} ml={-4}>
          <Box bg="primary" width={4} height={48} />
          <H6 ml={3} color="primary">
            <a
              children={`View examples`}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              css={css({ textDecoration: "none" })}
            />
          </H6>
        </Flex>
      )}
    </Flex>
  </Flex>
)

const FinishesPage = () => (
  <StaticQuery
    query={graphql`
      {
        page: datoCmsFinishesMaterial {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          seoKeywords
          heroTitle
          heroSubtitle
          heroImage {
            url
          }
          inspirationTitle
          inspirationSubtitle
          themes {
            image {
              url
            }
            title
            subtitle
            url
          }
          finishesTitle
          finishes {
            image {
              url
            }
            title
            subtitle
            url
            comingSoon
          }
          designStudioTitle
          designStudioSubtitle
          designStudioUrl
        }
      }
    `}
    render={data => (
      <>
        <HelmetDatoCms seo={data.page.seoMetaTags}>
          <meta name="keywords" content={data.page.seoKeywords} />
        </HelmetDatoCms>
        <Section
          image={
            data.page.heroImage &&
            data.page.heroImage.url &&
            data.page.heroImage.url.indexOf(".mp4") === -1
              ? data.page.heroImage.url
              : ""
          }
          video={
            data.page.heroImage &&
            data.page.heroImage.url &&
            data.page.heroImage.url.indexOf(".mp4") !== -1
              ? data.page.heroImage.url
              : undefined
          }
          title={data.page.heroTitle}
          subtitle={data.page.heroSubtitle}
          bg="backgroundReverse"
          color="textReverse"
          side="right"
          top={170}
        />
        <Box py={[5, 6]} bg="muted">
          <Container>
            <Box width="100%" maxWidth={660} mx="auto">
              <H1 children={data.page.inspirationTitle} textAlign="center" />
              <Text
                children={data.page.inspirationSubtitle}
                mt={[3, 4]}
                fontFamily="condensed"
                fontSize={[5, 6]}
                textAlign="center"
              />
            </Box>
            <Flex pt={5} flexWrap="wrap" m={-3}>
              {data.page.themes &&
                data.page.themes.map((theme, index) => (
                  <>
                    <Theme
                      image={theme.image && theme.image.url && theme.image.url}
                      title={theme.title}
                      subtitle={theme.subtitle}
                      // url={theme.url}
                      key={"theme" + index}
                    />
                  </>
                ))}
            </Flex>
            {data.page.designStudioUrl && (
              <Flex justifyContent="center" mt={4}>
                <Button
                  children={"Start Designing"}
                  as="a"
                  href={data.page.designStudioUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </Flex>
            )}
          </Container>
        </Box>
        <Box py={[5, 6]} bg="background" position="relative">
          <a
            id="library"
            name="anchor"
            css={css({
              content: "''",
              position: "absolute",
              left: "0px",
              top: "-90px",
              pointerEvents: "none",
            })}
          >
            {" "}
          </a>
          <Container>
            <Box width="100%" maxWidth={660} mx="auto">
              {data.page.finishesTitle && (
                <H2 children={data.page.finishesTitle} textAlign="center" />
              )}
            </Box>
            <Flex pt={5} flexWrap="wrap" m={-3} justifyContent="center">
              {data.page.finishes &&
                data.page.finishes.map((finish, index) => (
                  <>
                    <Finish
                      image={
                        finish.image && finish.image.url && finish.image.url
                      }
                      title={finish.title}
                      subtitle={finish.subtitle}
                      url={finish.url}
                      comingSoon={finish.comingSoon}
                      key={"finish" + index}
                    />
                  </>
                ))}
            </Flex>
          </Container>
        </Box>
        <div
          css={css({
            "@supports (-ms-ime-align:auto)": {
              display: "none",
            },
            "@media screen and (-ms-high-contrast: none)": {
              display: "none",
            },
          })}
        >
          <ScrollChanger
            children={
              <Section
                title={data.page.designStudioTitle}
                subtitle={data.page.designStudioSubtitle}
                buttonText={`Start Designing`}
                buttonLink={data.page.designStudioUrl}
                buttonLinkType="external"
                lottie={studioLottie}
                lottieCss={{
                  marginLeft: ["-25%", "0"],
                  marginBottom: ["-25%", "0"],
                }}
                bg="background"
                id="studioSection"
              />
            }
            start={0}
            end={1}
            length={1}
            attached={false}
          />
        </div>
        <div
          css={css({
            display: "none",
            "@supports (-ms-ime-align:auto)": {
              display: "block",
            },
            "@media screen and (-ms-high-contrast: none)": {
              display: "block",
            },
          })}
        >
          <Section
            graphic={Swatches}
            title={data.page.designStudioTitle}
            subtitle={data.page.designStudioSubtitle}
            buttonText={`Start Designing`}
            buttonLink={data.page.designStudioUrl}
            buttonLinkType="external"
          />
        </div>
      </>
    )}
  />
)

export default FinishesPage
